<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar :color=colorMenu flat app dense height="40px" dark>
      <v-btn  icon :to="{ name: complejo}">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{tituloHeader}}</v-toolbar-title>
      <v-spacer></v-spacer>
       <v-btn v-if=!editando class="mt-10"
          @click=editar()
           fab
          top
          :color=colorMenu
          fixed
          right 
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
    
    </v-app-bar>
    <v-card v-if=editando>
      <v-textarea rows="10"  v-model="datosconvertidos" outlined></v-textarea>
                          <v-card-actions >                                               
                                                <v-spacer></v-spacer>
                                                <v-btn color="green" class="mx-2 pa-4" dark dense @click="close()">Cancelar</v-btn>                                                
                                                <v-btn color="green" class="mx-2 pa-4" dark dense  @click="grabarItem(datosconvertidos)">Guardar Cambio</v-btn>
                                        </v-card-actions>
       
    </v-card>
     <v-text v-else v-html="datosObtenidos[0].contenido" id="normas">                                            
    
    
    </v-text>        
  </v-container>
</template>
<script>

import axios from 'axios'
export default {
  name:'normasAdmin',
  props: {
          tituloHeader: String,
          idcomplejo:Number,
          complejo: String,
          colorMenu:String,
  },
  data() {
    return {
            
      datosObtenidos:[],
      datosconvertidos:'',
      editando:false    };
  },
  created:
           async function() {              
               await axios.get(process.env.VUE_APP_API +'/complejo/normas',
                 {params:{
                   idComplejo: this.idcomplejo
                    }
          })
       .then((response) => {
                          this.datosObtenidos= response.data
                                                  
                          this.datosconvertidos=this.datosObtenidos[0].contenido.replace(/(<br>)/g, '\n')
      })

  },
  computed: {},
  methods: {
      editar() {
                this.editando=!this.editando
               
               // alert(this.editando)
      },
      close(){
                 this.editando=!this.editando

      },
      async grabarItem(item){
              //console.log(item)
                 let nuevoContenido=item.replace(/(\n)/g, '<br>')
               // console.log(nuevoContenido)
                 await axios.put(process.env.VUE_APP_API +'/complejo/normas',
                 {params:{
                   id: this.datosObtenidos[0].id,
                   idcomplejo: this.idcomplejo,
                   descripcion: this.datosObtenidos[0].descripcion,
                   contenido: nuevoContenido
                    }
                     })
                     .then((response) => {
                         
                         this.datosObtenidos[0].contenido=nuevoContenido                      


                         // this.datosObtenidos= response.data
                          
                          this.close()
                         // this.datosconvertidos=this.datosObtenidos[0].contenido.replace(/(<br>)/g, '\r\n')
      })

                    
      }
    
  }
};
</script>

